<template>
  
    <div class="col-xl-6 col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Simple line chart</h4>
            </div>
            <div class="card-body">
                <div id="simple-line-chart" class="ct-chart ct-golden-section chartlist-chart" ></div>
            </div>
        </div>
    </div>
</template>

<script>
import Chartist from 'chartist';

export default {
  mounted: function () {
    this.$nextTick(function () {
      console.log(Chartist);
      // Code that will run only after the
      // entire view has been rendered
      new Chartist.Line("#simple-line-chart", {labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
          series: [
            [12, 9, 7, 8, 5],
            [2, 1, 3.5, 7, 3],
            [1, 3, 4, 5, 6]
          ]
        }, {
          fullWidth: true,
          chartPadding: {
            right: 40
          },
          plugins: [
            Chartist.plugins.tooltip()
          ]
        });
    })
  }
}
</script>